import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const { t } = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null);
  const toast = useToast();
  store.dispatch("tenant/getAll");
  const tenantOptions = computed(() => store.getters["tenant/getSelectedItems"]);
  const tenantFilter = ref(null);
  if (router.currentRoute.params.id_tenant)
    tenantFilter.value = parseInt(router.currentRoute.id_tenant);
  const tableColumns = [
    { label: "swift_collector.id_external", key: "id_external", sortable: true },
    {
      label: "swift_collector.first_contact_time",
      key: "first_contact_time",
      sortable: true,
    },
    {
      label: "swift_collector.last_contact_time",
      key: "last_contact_time",
      sortable: true,
    },
    { label: "swift_collector.guid", key: "guid", sortable: true },
    { label: "swift_collector.manufacturer", key: "manufacturer", sortable: true },
    { label: "swift_collector.model", key: "model", sortable: true },
    {
      label: "swift_collector.hardware_revision",
      key: "hardware_revision",
      sortable: true,
    },
    {
      label: "swift_collector.software_revision",
      key: "software_revision",
      sortable: true,
    },
    { label: "swift_collector.serial_number", key: "serial_number", sortable: true },
    { label: "swift_collector.name", key: "name", sortable: true },
    { label: "swift_collector.description", key: "description", sortable: true },
    { label: "swift_collector.latitude", key: "latitude", sortable: true },
    { label: "swift_collector.longitude", key: "longitude", sortable: true },
    { label: "swift_collector.elevation", key: "elevation", sortable: true },
    { label: "swift_collector.connection_type", key: "connection_type", sortable: true },
    { label: "swift_collector.connection_pref", key: "connection_pref", sortable: true },
    { label: "swift_collector.network", key: "network", sortable: true },
    { label: "swift_collector.signal_strength", key: "signal_strength", sortable: true },
    { label: "swift_collector.is_new", key: "is_new", sortable: true },
    {
      label: "swift_collector.ethernet_mac_address",
      key: "ethernet_mac_address",
      sortable: true,
    },
    {
      label: "swift_collector.wifi_mac_address",
      key: "wifi_mac_address",
      sortable: true,
    },
    { label: "swift_collector.ip_address", key: "ip_address", sortable: true },
    { label: "swift_collector.wifi", key: "wif", sortable: true },
    { label: "swift_collector.ble", key: "ble", sortable: true },
    { label: "swift_collector.cell", key: "cell", sortable: true },
    { label: "swift_collector.gps", key: "gps", sortable: true },
    { label: "swift_collector.radio_gen2", key: "radio_gen2", sortable: true },
    { label: "swift_collector.ble_gen3", key: "ble_gen3", sortable: true },
    { label: "swift_collector.zigbee", key: "zigbee", sortable: true },
    { label: "swift_collector.zwave", key: "zwave", sortable: true },
    { label: "swift_collector.radio", key: "radio", sortable: true },
    {
      label: "swift_collector.id_swift_account",
      key: "id_swift_account",
      sortable: true,
    },
    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    {
      label: "date.updated_at",
      key: "updated_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
  ];

  if (ability.can('discover', 'SwiftSensor') == true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });
  const fetchList = (ctx, callback) => {
    store
      .dispatch("swift_gateway/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_tenant: tenantFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans["messages.error.server_error"],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };
  const refetchData = () => {
    refListTable.value.refresh();
  };
  watch([ tenantFilter, currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    tenantOptions,
    tenantFilter,
    refetchData,
  };
}
